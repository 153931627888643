<template>
  <div class="form-builder__field">
    <q-skeleton
      v-if="field.isLoading"
      :type="field.type === 'btn' ? 'QBtn' : 'QInput'"
    />

    <component
      v-else
      :is="components[field.type]"
      :ref="field.ref || ''"
      :schema="field"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'FormBuilderField',
  components: {
    InputField: defineAsyncComponent(() => import('./Input.vue')),
    PasswordField: defineAsyncComponent(() => import('./PasswordInput.vue')),
    SelectField: defineAsyncComponent(() => import('./Select.vue')),
    TagField: defineAsyncComponent(() => import('./Tag.vue')),
    TelField: defineAsyncComponent(() => import('./Tel.vue')),
    MultiselectField: defineAsyncComponent(() => import('./Multiselect.vue')),
    AddressInputField: defineAsyncComponent(() => import('./АddressInput.vue')),
    SwitchField: defineAsyncComponent(() => import('./Switch.vue')),
    TextField: defineAsyncComponent(() => import('./Text.vue')),
    EditorField: defineAsyncComponent(() => import('./Editor.vue')),
    DateField: defineAsyncComponent(() => import('./Date.vue')),
    DateTimeField: defineAsyncComponent(() => import('./DateTime.vue')),
    BtnField: defineAsyncComponent(() => import('./Button.vue')),
    PortalTargetField: defineAsyncComponent(() => import('./PortalTargetField.vue')),
    TableField: defineAsyncComponent(()=>import('./Table.vue'))
  },
  props: {
    field: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      components: {
        input: 'input-field',
        password: 'password-field',
        select: 'select-field',
        tag: 'tag-field',
        tel: 'tel-field',
        multiselect: 'multiselect-field',
        address: 'address-input-field',
        switch: 'switch-field',
        text: 'text-field',
        editor: 'editor-field',
        date: 'date-field',
        datetime: 'date-time-field',
        btn: 'btn-field',
        'portal-target': 'portal-target-field',
        table: 'table-field'
      }
    }
  }
}
</script>
